<script>
  import * as CJS from './canvasjs.min';
  import {mdiCheck, mdiClose, mdiPrinter} from "@mdi/js";
  import moment from "moment";
  import {mapGetters} from "vuex";

  var CanvasJS = CJS.Chart ? CJS : window.CanvasJS;

  export default {
    props: {
      'styles': {
        type: Object,
        default: () => ({})
      },
      'options': {
        type: Object,
        default: () => ({})
      },
      can_print: Boolean,
      report_title: String,
      report_details: String,
    },
    emits: ['chart-ref'],
    data() {
      return {
        saving_data: false,
        chart: null,
        updateChart: true,
        icons: {
          mdiCheck,
          mdiClose,
          mdiPrinter,
        },
      }
    },
    watch: {
      options: {
        handler(options, prevOptions) {
          this.updateChart = (!(options === prevOptions));
        },
      },
    },
    updated() {
      if (this.chart && this.updateChart) {
        this.chart.options = this.options;
        this.chart.render();
      }
    },
    mounted() {
      if (this.chart) {
        this.chart.destroy();
      }
      this.chart = new CanvasJS.Chart(this.$refs.chartContainer, this.options);
      this.chart.render();
      this.$emit('chart-ref', this.chart);

    },
    unmounted() {
      if (this.chart) {
        this.chart.destroy();
      }
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo', 'damayan', 'chapel', 'factory', 'coop', 'printing']),
      ...mapGetters('authentication', ['address', 'contact', 'name', 'department', 'position']),
    },
    methods: {
      print_data() {

        var imgData = this.company_logo
        var info_array = []

        info_array = [
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  {
                    text: this.report_details,
                    style: 'main_data',
                  },
                ],
              },
            ],
          }, {
            columns: [
              {image: this.chart.canvas.toDataURL(), width: 500, height: 200, style: 'logo'},
            ],
          },
        ]

        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = {
          pageSize: {
            width: 612,
            height: 936,
          },
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE GROUP OF COMPANIES',
                    {text: this.address, style: 'subheader'},
                    {
                      text: this.contact,
                      style: 'subheader',
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label',
                    },
                  ],
                  style: 'header',
                },
              ],
            },
            '_______________________________________________________________________________________________',
            {
              stack: [
                {
                  columns: [
                    {
                      text: this.report_title,
                      style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                        color: 'blue',
                      },
                    },
                  ],
                },
              ],
            },
            {
              columns: [{text: ' '}],
            },
            {
              columns: [{text: ' '}],
            },
            {
              stack: info_array,
            },
            {
              columns: [{text: ' '}],
            },

            {
              stack: [
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      text: 'PROCESSED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'DEPARTMENT RECEIVER',
                      style: 'main_data_2',
                    },
                    {
                      text: 'RECEIVED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.name,
                      style: 'main_data_2',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: '',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.position,
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'VERIFIED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: '',
                    },
                    {
                      text: '',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_________________________________________',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'BOARD OF DIRECTOR',
                      style: 'main_data_2',
                    },
                    {
                      text: '',
                    },
                    {
                      text: '',
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                {image: this.damayan, width: 54, height: 40, style: 'logo'},
                {image: this.chapel, width: 54, height: 40, style: 'logo'},
                {image: this.factory, width: 54, height: 40, style: 'logo'},
                {image: this.coop, width: 54, height: 40, style: 'logo'},
                {image: this.printing, width: 54, height: 40, style: 'logo'},
              ],
            },
          ],
          footer: {
            // columns: [
            //     {
            //         text: 'Generated with Goodlife Savings & Credit Cooperative System',
            //         alignment: 'center',
            //         style: 'tableExample'
            //     }
            // ]
          },
          styles: {
            tableExample: {
              fontSize: 9,
            },
            tableExample2: {
              fontSize: 7,
            },
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5], //[left, top, right, bottom]
            },
            subheader: {
              fontSize: 11,
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 11,
              bold: true,
              alignment: 'left',
            },
            main_data_2: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
          },
        }
        pdfMake.createPdf(docDefinition).open()
        this.saving_data = false
      }
    },
  }
  export {CanvasJS};
</script>
<template>
  <div>
    <div v-if="can_print">
      <h2 v-if="!saving_data" class="mx-2">PRINT
        <v-icon
          class="mr-2"
          color="success"
          @click="print_data"
        >
          {{icons.mdiPrinter}}
        </v-icon>
      </h2>
    </div>
    <div ref="chartContainer"></div>
  </div>
</template>
