<template>
  <CanvasJSChart :options="options" :can_print="dataPoints.length>0" :report_title="report_title" :report_details="report_details"
                 class="mt-4"/>
</template>

<script>
  import CanvasJSChart from '@/views/pages/charts/CanvasJSVueComponent';

  export default {
    props: {
      dataPoints: Array,
      report_title: String,
      report_details: String,
    },
    components: {
      CanvasJSChart
    },
    data() {
      return {
        options: {
          theme: this.$vuetify.theme.dark ? "dark2" : "light2",
          animationEnabled: true,
          zoomEnabled: true,
          legend: {
            cursor: "pointer",
            itemclick: function (e) {
              if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
              } else {
                e.dataSeries.visible = true;
              }
              e.chart.render();
            }
          },
          data: this.dataPoints
        },
      }
    },
  }
</script>

<style scoped>

</style>
